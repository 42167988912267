import { IonContent, IonHeader, IonPage } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import queryString from "query-string";
import Header from '../components/Header';
import Footer from '../components/Footer';
import ProductDetailSection from '../components/HomeElements/ProductDetailSection';
import HomeHowToMeasure from '../components/HomeElements/HomeHowToMeasure';
import MostPopular from '../components/HomeElements/MostPopular';
import Menu from '../components/Menu';
import * as ServerAPI from '../common/ServerAPI';
import { useAppStateAPI } from '../common/AppStateAPI';
import { APIData } from '../common/DataTypes';

import { Helmet } from 'react-helmet';

const ProductDetails: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {

    const location = useLocation();
    const [productURLName, setProductURLName] = useState("");
    const [showAddOn, setShowAddOn] = useState(true);
    const { showToastMsg, addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();

    const [productDetail, setProductDetail] = useState<APIData.ProductDetail>();

    useEffect(() => {
        const urlParams = queryString.parse(location.search);
        if ('no_addon' in urlParams) {
            setShowAddOn(false);
        } else {
            setShowAddOn(true);
        }
        setProductURLName(match.params.id);
    }, [match, location.search]);

    useEffect(() => {
        fetchProductDetail();
    }, [productURLName, showAddOn]);

    const fetchProductDetail = () => {
        if (productURLName) {
            addProcessingRequests();
            ServerAPI.getProductByURL(productURLName, showAddOn).then((response) => {
                if (response.products.length > 0) {
                    setProductDetail(response.products[0]);
                } else if (response.message) {
                    showToastMsg('error', response.message);
                } else {
                    showToastMsg('error', 'Error occurred while processing the request');
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }

    const addToWishlist = (productID: number) => {
        addProcessingRequests();
        ServerAPI.addItemToWishlist(productID).then((response) => {
            if (productDetail !== undefined && response.success) {
                setProductDetail({ ...productDetail, wishlist_item_id: response.wishlist_item_id });
                showToastMsg('success', response.message);
            } else if (response.message) {
                showToastMsg('error', response.message);
            } else {
                showToastMsg('error', 'Error occurred while processing the request');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    const removeFromWishlist = (wishlistItemID: number) => {
        addProcessingRequests();
        ServerAPI.removeItemFromWishlist(wishlistItemID).then((response) => {
            if (productDetail !== undefined && response.success) {
                setProductDetail({ ...productDetail, wishlist_item_id: 0 });
                showToastMsg('success', response.message);
            } else if (response.message) {
                showToastMsg('error', response.message);
            } else {
                showToastMsg('error', 'Error occurred while processing the request');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    return (
        <>
            {productDetail !== undefined && (
                <Helmet>‍
                    <title>Customized Ready to install Mosquito nets with frames | {productDetail.product_name}</title>
                    <meta name='title' content='Customized Ready to install Mosquito nets with frames' />
                    <meta property='og:url' content={`https://www.venetz.in/product/${productDetail.product_url_name}`} />
                    <meta property='og:url' content='https://www.venetz.in/product/' />
                    <meta property='og:type' content='website' />
                    <meta property='og:title' content={productDetail.product_meta_title} />
                    <meta property='og:description' content={productDetail.product_meta_description} />
                    <meta property='og:site_name' content='VENETZ' />
                </Helmet>
            )}
            <IonPage>
                {/* <Menu /> */}
                <IonHeader>
                    <Header />
                </IonHeader>
                <IonContent fullscreen id="main-content">
                    {productDetail !== undefined && (
                        <>
                            <ProductDetailSection productDetail={productDetail} showAddOn={showAddOn} addToWishlist={addToWishlist} removeFromWishlist={removeFromWishlist} />
                            <HomeHowToMeasure />
                            <MostPopular />
                            <Footer />
                        </>
                    )}

                </IonContent>
            </IonPage>
        </>
    );
};
export default ProductDetails;
