import { IonRouterLink } from '@ionic/react';
import React from 'react';
import { PageLinks } from '../common/Constants';
const Footer: React.FC = () => {
    return (
        <div>
            <footer className="footer-01">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-10 col-md-10">
                            <aside className="widget about-widget">
                                <div className="foo-logo">
                                    <IonRouterLink routerLink={PageLinks.HOME}><img src="assets/images/footer_logo.svg" alt="" /></IonRouterLink>
                                </div>
                            </aside>
                        </div>
                        <div className="col-lg-2 col-md-2">
                            <aside className="widget about-widget">
                                <div className="ab-social">
                                    <a href='https://api.whatsapp.com/send?phone=919585520356' target='_blank' rel='noreferrer' className='ab-social-icons'><i className="twi-whatsapp"></i></a>
                                    <a href='https://www.instagram.com/venetzindia/' target='_blank' rel='noreferrer' className='ab-social-icons'><i className="twi-instagram"></i></a>
                                    <a href='https://www.facebook.com/Venetz-101495215003250/' target='_blank' rel='noreferrer' className='ab-social-icons'><i className="twi-facebook"></i></a>
                                </div>
                            </aside>
                        </div>
                    </div>
                    <hr className="footer_line" />
                    <div className="row">
                        <div className="col-lg-6 col-md-4">
                            <aside className="widget about-widget">
                                <p>
                                    By buying Our high quality Mosquito nets online, it covers the bed totally so that the insects & Mosquitoes don’t have a chance to get inside the bed net while you sleep. Protect your loved ones from Mosquito Bites with Venetz Protective net solutions for Home ! Custom sized Mosquito net frames serve to be the best part in mosquito protection. Venetz can manufacture any size of mosquito net solutions for all your home needs, starting from your bed rooms to Custom sized Outdoor mosquito nets, especially for pets aswell.
                                </p>
                            </aside>
                        </div>
                        <div className="col-lg-2 col-md-4 col-custome-1">
                            <aside className="widget">
                                <h3 className="widget-title">Useful Links</h3>
                                <ul>
                                    <li><IonRouterLink routerLink={PageLinks.MY_ORDERS} className='widget-title-text'>My Orders</IonRouterLink></li>
                                    <li><IonRouterLink routerLink={PageLinks.MY_PROFILE} className='widget-title-text'>My Profile</IonRouterLink></li>
                                    <li><IonRouterLink routerLink={PageLinks.MY_WISHLIST} className='widget-title-text'>My wishlist</IonRouterLink></li>
                                    <li><IonRouterLink routerLink={PageLinks.CONTACT} className='widget-title-text'>Contact us</IonRouterLink></li>
                                </ul>
                            </aside>
                        </div>
                        <div className="col-lg-2 col-md-4 col-custome-2">
                            <aside className="widget">
                                <h3 className="widget-title">Quick Link</h3>
                                <ul>
                                    <li><IonRouterLink routerLink={PageLinks.ABOUT} className='widget-title-text'>About Us</IonRouterLink></li>
                                    <li><IonRouterLink routerLink={PageLinks.SHIPPING} className='widget-title-text'>Shipping & Returns</IonRouterLink></li>
                                    <li><IonRouterLink routerLink={PageLinks.CONTACT} className='widget-title-text'>Contact Us</IonRouterLink></li>
                                    <li><IonRouterLink routerLink={PageLinks.TERMS_CONDITIONS} className='widget-title-text'>Terms and Conditions</IonRouterLink></li>
                                    <li><IonRouterLink routerLink={PageLinks.PRIVACY_POLICY} className='widget-title-text'>Privacy Policy</IonRouterLink></li>
                                    <li><IonRouterLink routerLink={PageLinks.CANCELLATION_POLICY} className='widget-title-text'>Cancellation and Refund Policy</IonRouterLink></li>
                                    <li><a href='http://blog.venetz.in/' target='_blank' rel='noreferrer' className='widget-title-text'>Blog</a></li>
                                </ul>
                            </aside>
                        </div>
                        <div className="col-lg-2 col-md-6 col-custome-3">
                            <aside className="widget contact-widget">
                                <h3 className="widget-title">Store Contact</h3>
                                <p>
                                    110, Sengunthar Nagar, <br />
                                    Vengamedu, Karur- 639 006, Tamil Nadu, India.
                                </p>
                                <p>
                                    Email: <a href='mailto:sales@venetz.in' className='text-white'>sales@venetz.in</a> <br />
                                    WhatsApp: <a href='tel:+919585520356' className='text-white'>+91 95855 20356</a>
                                </p>
                            </aside>
                        </div>
                    </div>
                </div>
            </footer>
            <section className="copyright-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="copys-text"><i className="twi-copyright"></i>Copyright VENETZ 2023 | All Rights Reserved | A Product of Brahma e Solutions</div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
export default Footer;