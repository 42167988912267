import { IonRouterLink } from '@ionic/react';
import React, { Fragment, useEffect, useState } from 'react';
import { AttributeTypeDisplayType, PageLinks, ProductImageType } from '../../common/Constants';
import { APIData } from '../../common/DataTypes';
import * as Utilities from '../../common/Utilities';
import * as ServerAPI from '../../common/ServerAPI';
import { useAppStateAPI } from '../../common/AppStateAPI';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useHistory, useLocation } from 'react-router';

const ProductDetailSection: React.FC<{ productDetail: APIData.ProductDetail, showAddOn?: boolean, addToWishlist: (productID: number) => void, removeFromWishlist: (wishlistItemID: number) => void }> = ({ productDetail, showAddOn, addToWishlist, removeFromWishlist }) => {

    const location = useLocation();
    const history = useHistory();
    const { showToastMsg, addProcessingRequests, reduceProcessingRequests, setCartCount, customerLogin, setCustomerLoginRedirectLink } = useAppStateAPI();
    const [selectedVariant, setSelectedVariant] = useState<APIData.ProductVariant>();
    const [attributeTypeCount, setAttributeTypeCount] = useState(0);
    const [productQuantity, setProductQuantity] = useState(1);
    const [selectedAttributeValues, setSelectedAttributeValues] = useState<APIData.AttributeValue[]>([]);
    const [productImages, setProductImages] = useState<APIData.ProductImage[]>([]);
    const [finalPrice, setFinalPrice] = useState(0);

    useEffect(() => {
        if (productDetail.product_variants.length > 0) {
            if (productDetail.product_has_attributes) {
                setSelectedVariant(productDetail.product_variants[0]);
            }
            setAttributeTypeCount(productDetail.product_variants[0].product_variant_attrib_values.length);
            const selectedAttrbVals: APIData.AttributeValue[] = [];
            productDetail.product_variants[0].product_variant_attrib_values.forEach(prodVariantAttribVal => {
                productDetail.product_attribute_types.forEach(attribType => {
                    attribType.product_attribute_values.forEach(attribVal => {
                        if (attribVal.attribute_value_id == prodVariantAttribVal.attribute_value_id) {
                            selectedAttrbVals.push(attribVal);
                        }
                    })
                })
            })
            setSelectedAttributeValues(selectedAttrbVals);
        }
        if (!productDetail.product_has_attributes || productDetail.product_image_type === ProductImageType.ProductBased) {
            setProductImages(productDetail.product_images);
        }
        setProductQuantity(1);
    }, [productDetail]);

    useEffect(() => {
        const selAttributeValIDs = selectedAttributeValues.map(val => val.attribute_value_id);
        productDetail.product_variants.forEach(variant => {
            const matchedValues = variant.product_variant_attrib_values.filter(val => selAttributeValIDs.includes(val.attribute_value_id));
            if (matchedValues.length === attributeTypeCount) {
                setSelectedVariant(variant);
            }
        })
    }, [selectedAttributeValues]);

    useEffect(() => {
        if (selectedVariant) {
            let images: APIData.ProductImage[] = [];
            if (productDetail.product_image_type === ProductImageType.VariantBased) {
                images = productDetail.product_images.filter(image => image.product_variant_id === selectedVariant.product_variant_id)
                setProductImages(images);
            } else if (productDetail.product_image_type === ProductImageType.AttributeBased) {
                const imgAttributeTypeValues = selectedAttributeValues.filter(attribVal => attribVal.attribute_type_id === productDetail.product_image_attribute_type_id);
                if (imgAttributeTypeValues.length) {
                    images = productDetail.product_images.filter(image => image.attribute_value_id === imgAttributeTypeValues[0].attribute_value_id);
                }
                setProductImages(images);
            }
            let mainProductPrice: number = parseFloat(selectedVariant.product_variant_selling_price);
            if (productDetail.addon_products !== undefined && productDetail.addon_products.length > 0) {
                productDetail.addon_products.forEach(addonProduct => {
                    if (addonProduct.product_variants.length > 0) {
                        mainProductPrice += parseFloat(addonProduct.product_variants[0].product_variant_selling_price);
                    }
                })
            }
            setFinalPrice(mainProductPrice);
        }

    }, [selectedVariant]);

    const updateProductQuantity = (reduce?: boolean) => {
        if (reduce && productQuantity > 1) {
            setProductQuantity(currQty => currQty - 1);
        }
        if (!reduce && productQuantity < 10) {
            setProductQuantity(currQty => currQty + 1);
        }
    }

    const updateSelectedAttributeValue = (newAttribValue: APIData.AttributeValue) => {
        const updatedAttribValues = selectedAttributeValues.map(attribValue => {
            if (attribValue.attribute_type_id === newAttribValue.attribute_type_id) {
                return newAttribValue;
            } else {
                return attribValue;
            }
        })
        setSelectedAttributeValues(updatedAttribValues);
    }

    const addToCart = () => {
        if (selectedVariant && productQuantity) {
            const addonProductVariantIDs: number[] = [];
            if (showAddOn && productDetail.addon_products !== undefined && productDetail.addon_products.length > 0) {
                productDetail.addon_products.forEach(addonProduct => {
                    addonProduct.product_variants.forEach(variant => {
                        addonProductVariantIDs.push(variant.product_variant_id);
                    });
                });
            }
            addProcessingRequests();
            ServerAPI.addItemToCart(selectedVariant.product_variant_id, productQuantity, addonProductVariantIDs).then((response) => {
                if (response.success) {
                    if (response.cart_count !== undefined) {
                        setCartCount(response.cart_count);
                    }
                    showToastMsg('success', response.message);
                } else {
                    showToastMsg('error', 'Could not add item to cart');
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }

    return (
        <div>
            <section className="single-product-section">
                <div className="sec-heading rotate-rl">Product <span> Details</span></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-12">
                            <Carousel>
                                {productImages.map((prodImg, index) => (
                                    <div key={index} data-slide-to={index}>
                                        <img src={prodImg.product_image} alt={prodImg.product_image_caption} />
                                    </div>
                                ))}
                            </Carousel>
                            {/* <div id="product-slider" className="carousel slide product-slider" data-ride="carousel">
                                <div className="carousel-inner">
                                    {productImages.map((prodImg, index) => (
                                        <div className="carousel-item active" key={index} data-slide-to={index}>
                                            <div className="ps-img">
                                                <img src={prodImg.product_image} alt={prodImg.product_image_caption} />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <ol className="carousel-indicators clearfix">
                                    {productImages.map((prodImg, index) => <li data-target="#product-slider" data-slide-to={index} className="active" key={index}><img src={prodImg.product_image} alt={prodImg.product_image_caption} /></li>)}
                                </ol>
                            </div> */}
                        </div>
                        <div className="col-lg-7 col-md-12">
                            <div className="sin-product-details">
                                <h3>{productDetail.product_name}</h3>
                                <div className="woocommerce-product-rating">
                                    <div className="woocommerce-review-link">{productDetail.product_short_description}</div>
                                </div>
                                <div className="product-price clearfix">
                                    <span className="price">
                                        <span><span className="woocommerce-Price-currencySymbol">₹ </span>{Utilities.formatIndianNumber(finalPrice)}</span>
                                    </span>
                                    <p className="inclusive_gst">Inclusive of GST + Free Shipping</p>
                                </div>
                                {productDetail.product_attribute_types.map((attributeType, index) => (
                                    <div className="product-color" key={index}>
                                        <h5>{attributeType.attribute_type_name}</h5>
                                        {attributeType.attribute_type_display_type === AttributeTypeDisplayType.ColorSwatch && attributeType.product_attribute_values.map((attributeValue, valIndex) => (
                                            <div className="active_color" style={{ background: `${attributeValue.attribute_value_color_code}`, border: '2px solid #ffa927' }} key={valIndex}>
                                                <label className="checkbox-default" htmlFor="color1">
                                                    <input type="checkbox" id="color1" className="active-colors" checked={selectedAttributeValues.filter(val => val.attribute_value_id === attributeValue.attribute_value_id).length ? true : false} onChange={() => updateSelectedAttributeValue(attributeValue)} />
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                                <div className="col-12 p-0">
                                    <div className="product-color">
                                        <h5>What you get for this price?</h5>
                                    </div>
                                </div>
                                {productDetail.addon_products !== undefined && productDetail.addon_products.length > 0 && (
                                    <div className="col-12 p-0 addon_products">
                                        <div className="col-4 p-0">
                                            <div className="single-shop-product">
                                                <div className="sp-thumb">
                                                    {productImages.length > 0 && (
                                                        <img src={productImages[0].product_image} alt={productImages[0].product_image_caption} className="" />
                                                    )}
                                                    {/* <div className="pro-badge">
                                                        <p className="sale">Sale</p>
                                                    </div> */}
                                                </div>
                                                <div className="sp-details">
                                                    <h4>{productDetail.product_name}</h4>
                                                    {/* <div className="product-price clearfix">
                                                        {selectedVariant !== undefined && (
                                                            <span className="price">
                                                                {selectedVariant.product_variant_price !== selectedVariant.product_variant_selling_price && (
                                                                    <del><span><span className="woocommerce-Price-currencySymbol">₹</span>{Utilities.formatIndianNumber(parseFloat(selectedVariant.product_variant_price))}</span></del>
                                                                )}
                                                                <ins><span><span className="woocommerce-Price-currencySymbol">₹</span> {Utilities.formatIndianNumber(parseFloat(selectedVariant.product_variant_selling_price))}</span></ins>
                                                            </span>
                                                        )}

                                                        <p className="inclusive_gst_small">Inclusive of GST</p>
                                                    </div> */}
                                                    {/* <div className="sp-details-hover">
                                                        <IonRouterLink className="sp-cart" routerLink={PageLinks.PRODUCT_DETAILS + '/' + productDetail.product_url_name + '?no_addon=1'}><i className="twi-cart-plus"></i><span>Add to cart</span></IonRouterLink>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        {productDetail.addon_products.map((addonProduct, addIndex) => {
                                            if (addonProduct.product_variants.length > 0) {
                                                const addonVariant = addonProduct.product_variants[0];
                                                return (
                                                    <Fragment key={addIndex}>
                                                        <div className="addon_plus">+</div>
                                                        <div className="col-4 p-0">
                                                            <div className="single-shop-product">
                                                                <div className="sp-thumb">
                                                                    {addonProduct.product_images.length > 0 && (
                                                                        <img src={addonProduct.product_images[0].product_image} alt={addonProduct.product_images[0].product_image_caption} className="" />
                                                                    )}
                                                                </div>
                                                                <div className="sp-details">
                                                                    <h4>{addonProduct.product_name}</h4>
                                                                    {/* <div className="product-price clearfix">
                                                                        <span className="price">
                                                                            {addonVariant.product_variant_price !== addonVariant.product_variant_selling_price && (
                                                                                <del><span><span className="woocommerce-Price-currencySymbol">₹</span>{Utilities.formatIndianNumber(parseFloat(addonVariant.product_variant_price))}</span></del>
                                                                            )}
                                                                            <ins><span><span className="woocommerce-Price-currencySymbol">₹</span> {Utilities.formatIndianNumber(parseFloat(addonVariant.product_variant_selling_price))}</span></ins>
                                                                        </span>
                                                                        <p className="inclusive_gst_small">Inclusive of GST</p>
                                                                    </div> */}
                                                                    {/* <div className="sp-details-hover">
                                                                        <IonRouterLink className="sp-cart" routerLink={PageLinks.PRODUCT_DETAILS + '/' + addonProduct.product_url_name + '?no_addon=1'}><i className="twi-cart-plus"></i><span>Add to cart</span></IonRouterLink>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )
                                            }
                                        })}
                                    </div>
                                )}

                                <div className="col-12 add_to_cart_box">
                                    <div className="col-12 col-lg-6 col-md-6 p-0">
                                        <div className="product-price clearfix">
                                            <span className="price">
                                                <span><span className="woocommerce-Price-currencySymbol">₹ </span>{Utilities.formatIndianNumber(finalPrice)}</span>
                                            </span>
                                            <p className="inclusive_gst colored">Inclusive of GST + Free Shipping</p>
                                        </div>
                                        {/* <div className="add_to_cart_box_delivery_note">FREE delivery Saturday, 6 May. Order within <span className="colored">4 hrs 58 mins.</span></div>
                                        <div className="add_to_cart_box_delivery_note">Product Ref ID # 968403</div> */}
                                        <div className="add_to_cart_box_delivery_note"><span className="colored">In stock</span></div>
                                        <div className="product-cart-qty">
                                            <span className="qty_text">Quantity</span>
                                            <div className="quantityd clearfix">
                                                <button className="qtyBtn btnMinus" type="button" onClick={() => updateProductQuantity(true)}><span>-</span></button>
                                                <input name="qty" value={productQuantity} title="Qty" className="input-text qty text carqty" type="text" onChange={(e) => setProductQuantity(!isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : 0)} />
                                                <button className="qtyBtn btnPlus" type="button" onClick={() => updateProductQuantity()}>+</button>
                                            </div>
                                            <div className="product-social">
                                                {/* <IonRouterLink routerLink={PageLinks.MY_WISHLIST} className="Whislist"><i className="twi-heart"></i></IonRouterLink> */}
                                                <a href="#" onClick={(e => {
                                                    e.preventDefault();
                                                    if (customerLogin) {
                                                        if (productDetail.wishlist_item_id) {
                                                            removeFromWishlist(productDetail.wishlist_item_id);
                                                        } else {
                                                            addToWishlist(productDetail.product_id);
                                                        }
                                                    } else {
                                                        setCustomerLoginRedirectLink(location.pathname);
                                                        history.push(PageLinks.LOGIN);
                                                    }
                                                })} className={`Whislist${productDetail.wishlist_item_id ? ' active' : ''}`}><i className="twi-heart"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 col-md-6 p-0">
                                        <div className="product-add-cart">
                                            {/* <IonRouterLink routerLink={PageLinks.CART} className="add-to-cart-btn">Add To Cart</IonRouterLink> */}
                                            <button type='button' className="add-to-cart-btn" onClick={addToCart}>Add To Cart</button>
                                        </div>
                                        <div className="product-customize">
                                            <IonRouterLink routerLink={PageLinks.PRODUCT_CUSTOMIZE} className="add-to-cart-btn">Customize Now</IonRouterLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
export default ProductDetailSection;