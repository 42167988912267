import { IonContent, IonHeader, IonPage, IonRouterLink } from '@ionic/react';
import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MyAccountLeftSidebar from './MyAccountLeftSidebar';
import { PageLinks } from '../common/Constants';
import * as ServerAPI from '../common/ServerAPI';
import * as Utilities from '../common/Utilities';
import { useAppStateAPI } from '../common/AppStateAPI';

import { Helmet } from 'react-helmet';

const ChangePassword: React.FC = () => {

    const { showToastMsg, addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [retypePassword, setRetypePassword] = useState('');

    const saveCustomerPassword = () => {
        if (!oldPassword) {
            showToastMsg('error', 'Please enter your old password');
        } else if (!newPassword) {
            showToastMsg('error', 'Please enter your new password');
        } else if (newPassword !== retypePassword) {
            showToastMsg('error', 'New password and retype password do not match');
        } else {
            addProcessingRequests();
            ServerAPI.updateCustomerPassword(oldPassword, newPassword).then((response) => {
                if (response.success) {
                    Utilities.updateCustomerAPIKey(response.customer_api_key);
                    showToastMsg('info', response.message);
                    setOldPassword('');
                    setNewPassword('');
                    setRetypePassword('');
                } else if (response.message) {
                    showToastMsg('error', response.message);
                } else {
                    showToastMsg('error', 'Error occurred while processing the request');
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }

    return (
        <>
            <Helmet>‍
                <title>Customized Ready to install Mosquito nets with frames | Change Password</title>
                <meta name='title' content='Customized Ready to install Mosquito nets with frames' />
                <meta property='og:url' content='https://www.venetz.in/change-password' />
                <meta property='og:type' content='website' />
                <meta property='og:title' content='VENETZ | Change Password' />
                <meta property='og:description' content='VENETZ | Change Password' />
                <meta property='og:site_name' content='VENETZ' />
            </Helmet>
            <IonPage>
                {/* <Menu /> */}
                <IonHeader>
                    <Header />
                </IonHeader>
                <IonContent fullscreen id="main-content">
                    <section className="page-banner">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <span className="round-shape"></span>
                                    <h2 className="banner-title">My Account</h2>
                                    <div className="bread-crumb"><IonRouterLink routerLink={PageLinks.HOME} className='bread-crumb-text'>Home</IonRouterLink> / My Account / Change Password</div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="blog-section pt-5">
                        <div className="container-fluid">
                            <div className="row">
                                <MyAccountLeftSidebar />
                                <div className="col-lg-9 col-md-7">
                                    <h3 className="sec-title">Change Password</h3>
                                    <div className="register-form">
                                        <form action="#" method="post">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <input type="password" placeholder="Current Password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                                                </div>
                                                <div className="col-lg-6">
                                                    <input type="password" placeholder="New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                                </div>
                                                <div className="col-lg-6">
                                                    <input type="password" placeholder="Confirm Password" value={retypePassword} onChange={(e) => setRetypePassword(e.target.value)} />
                                                </div>
                                            </div>
                                            <input type="button" value="Save" onClick={saveCustomerPassword} />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </IonContent>
            </IonPage>
        </>
    );
};
export default ChangePassword;
